var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-layout" }, [
    !_vm.signModel
      ? _c("div", { staticClass: "new-flex" }, [
          _c(
            "div",
            [
              _c(
                "v-layout",
                [
                  _c("v-img", {
                    attrs: {
                      height: "60",
                      contain: "",
                      src: require("../../assets/" + _vm.logoUrl),
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mx-auto mt-10" },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: " card-box", attrs: { flat: "" } },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "loginForm",
                                  staticClass: "mt-3",
                                  attrs: { autocomplete: "off" },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "append-icon": "mdi-account",
                                      rules: _vm.$_requiredValidation,
                                      id: "username_field",
                                      label: _vm.$t("message.login.userName"),
                                      type: "text",
                                      "hide-details": "",
                                    },
                                    on: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.loginHandler($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.model.username,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "username", $$v)
                                      },
                                      expression: "model.username",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      id: "password_field",
                                      rules: _vm.$_requiredValidation,
                                      "append-icon": _vm.showPassword
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      label: _vm.$t("message.login.password"),
                                      type: _vm.showPassword
                                        ? "text"
                                        : "password",
                                      "hide-details": "",
                                      autocomplete: "new-password",
                                    },
                                    on: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.loginHandler($event)
                                      },
                                      "click:append": function ($event) {
                                        _vm.showPassword = !_vm.showPassword
                                      },
                                    },
                                    model: {
                                      value: _vm.model.password,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "password", $$v)
                                      },
                                      expression: "model.password",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      id: "domain",
                                      rules: _vm.$_requiredValidation,
                                      label: _vm.$t("message.login.host"),
                                      type: "text",
                                      suffix: _vm.defaultHost,
                                      "hide-details": !_vm.domainHasMistake,
                                    },
                                    on: {
                                      keyup: [
                                        _vm.doAlbhaNumericConversion,
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.loginHandler($event)
                                        },
                                      ],
                                    },
                                    model: {
                                      value: _vm.hostRef,
                                      callback: function ($$v) {
                                        _vm.hostRef = $$v
                                      },
                                      expression: "hostRef",
                                    },
                                  }),
                                  _c("v-select", {
                                    attrs: {
                                      "append-icon": "mdi-translate",
                                      id: "lang_select",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      label: _vm.$t("message.login.language"),
                                      items: _vm.language,
                                      "item-text": "title",
                                      "item-value": "value",
                                    },
                                    model: {
                                      value: _vm.defaultLang,
                                      callback: function ($$v) {
                                        _vm.defaultLang = $$v
                                      },
                                      expression: "defaultLang",
                                    },
                                  }),
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "mt-5",
                                      attrs: { "justify-center": "" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "button-deign",
                                          attrs: { loading: _vm.loading },
                                          on: { click: _vm.loginHandler },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("message.layout.login")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-layout",
                                    { attrs: { "justify-center": "" } },
                                    [
                                      _c("p", { staticClass: "my-3" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("message.layout.ortext")
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: { "justify-center": "" },
                                      on: { click: _vm.rfidClick },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        { staticClass: "button-deign" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "message.layout.rfidsignin"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _c("div", { staticClass: "new-flex" }, [
          _c(
            "div",
            [
              _c(
                "v-layout",
                [
                  _c("v-img", {
                    attrs: {
                      height: "60",
                      contain: "",
                      src: require("../../assets/" + _vm.logoUrl),
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mx-auto mt-10" },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: " card-box", attrs: { flat: "" } },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "rfidform",
                                  staticClass: "mt-3",
                                  attrs: { autocomplete: "off" },
                                },
                                [
                                  _c("v-text-field", {
                                    ref: "passwordField",
                                    staticClass: "mb-3",
                                    attrs: {
                                      rules: _vm.$_requiredValidation,
                                      outlined: "",
                                      dense: "",
                                      id: "password_field",
                                      "append-icon": _vm.showPassword
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      label: _vm.$t("message.layout.ScanRFID"),
                                      type: _vm.showPassword
                                        ? "text"
                                        : "password",
                                      "hide-details": "",
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.showPassword = !_vm.showPassword
                                      },
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.rfidHandler($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.from.password,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.from, "password", $$v)
                                      },
                                      expression: "from.password",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      rules: _vm.$_requiredValidation,
                                      outlined: "",
                                      dense: "",
                                      id: "domain",
                                      label: _vm.$t("message.login.host"),
                                      type: "text",
                                      suffix: _vm.defaultHost,
                                      "hide-details": !_vm.domainHasMistake,
                                    },
                                    on: {
                                      keyup: [
                                        _vm.doAlbhaNumericConversionRfid,
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.rfidHandler($event)
                                        },
                                      ],
                                    },
                                    model: {
                                      value: _vm.hostRefRfid,
                                      callback: function ($$v) {
                                        _vm.hostRefRfid = $$v
                                      },
                                      expression: "hostRefRfid",
                                    },
                                  }),
                                  _c("v-select", {
                                    attrs: {
                                      "append-icon": "mdi-translate",
                                      id: "lang_select",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      label: _vm.$t("message.login.language"),
                                      items: _vm.language,
                                      "item-text": "title",
                                      "item-value": "value",
                                    },
                                    model: {
                                      value: _vm.defaultLang,
                                      callback: function ($$v) {
                                        _vm.defaultLang = $$v
                                      },
                                      expression: "defaultLang",
                                    },
                                  }),
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "mt-5",
                                      attrs: { "justify-center": "" },
                                      on: { click: _vm.signInPassword },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        { staticClass: "button-deign" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "message.layout.SignInpassword"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }